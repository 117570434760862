<div class="checkout-panel" *ngIf="isCalculatorVisible">
    <div class="loading-overlay" *ngIf="isPanelLoading || isLoading">
        <div class="loading-spinner"></div>
    </div>
    <header class="panel-header">
        <div class="total-wrapper">
            <div class="total-price">
                {{ price | currency: 'EUR' }}
                <span class="success-message" *ngIf="showSuccessMessage">✓ Registado!</span>
                <span class="error-message" *ngIf="showErrorMessage">
                  <mat-icon>error_outline</mat-icon> Erro ao registar
                </span>
            </div>
            <div class="total-registered">
                Total Registado: {{ totalRegistered | currency: 'EUR' }}
            </div>
        </div>
        <button class="close-btn" (click)="close()">
            <mat-icon>close</mat-icon>
        </button>
    </header>
    <section class="product-list">
        <button
            *ngFor="let product of products"
            class="product"
            [ngStyle]="{'background-color': product.backgroundColor || '#13b2c3', 'color': product.color || '#fff'}"
            [ngClass]="{'selected': product.touchedTimes > 0}"
            (click)="priceClicked(product.id)">
            <span class="product-name">{{ product.name }}</span>
            <span class="product-price">{{ product.price | currency:'EUR' }}</span>
            <span *ngIf="product.touchedTimes" class="counter">{{ product.touchedTimes }}</span>
        </button>
    </section>

    <footer class="action-buttons">
        <button (click)="resetCalculatingScore()" class="action-btn clear-btn">Limpar</button>
        <button (click)="partialDeleteCalculator()" class="action-btn partial-delete"
                [ngClass]="{'focus': isPartialDeletingCalculator}">Remover
        </button>
        <button (click)="showChangePanel()" class="action-btn troco-btn" [disabled]="price  <= 0 || isLoading">Troco
        </button>
        <button (click)="registerTransaction()" class="reset-btn register-btn" [disabled]="price <= 0 || isLoading">
            <ng-container *ngIf="!isLoading">Registar</ng-container>
            <ng-container *ngIf="isLoading">
                <mat-icon class="loading-icon">refresh</mat-icon>
            </ng-container>
        </button>
    </footer>
</div>

<div class="change-panel" *ngIf="isChangePanelVisible">
    <button class="close-btn mr-auto" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <header class="panel-header">
        <div class="total-price">Total: {{ price | currency:'EUR' }}</div>
    </header>
    <div class="error-message" *ngIf="showErrorMessage">
        {{ errorMessage }}
    </div>

    <section class="change-section">
        <div class="field">
            <label>Valor recebido:</label>
            <input type="number"
                   [(ngModel)]="amountReceived"
                   (input)="onAmountReceivedChanged(amountReceived)"
                   placeholder="Insira o valor recebido"
                   [value]="amountReceived == null ? '' : amountReceived"/>
        </div>
        <div class="field">
            <label>Troco:</label>
            <div class="troco">
                {{
                    (amountReceived == null || amountReceived === 0)
                        ? (0 | currency:'EUR')
                        : ((amountReceived - price) | currency:'EUR')
                }}
            </div>
        </div>
    </section>

    <footer class="action-buttons">
        <button class="reset-btn" (click)="goBack()">Voltar</button>
        <button (click)="registerTransactionByChange()" class="reset-btn register-btn" [disabled]="price <= 0 || isLoading">
            <ng-container *ngIf="!isLoading">Registar</ng-container>
            <ng-container *ngIf="isLoading">
                <mat-icon class="loading-icon">refresh</mat-icon>
            </ng-container>
        </button>
    </footer>
</div>

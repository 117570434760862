import {Component, ViewEncapsulation} from '@angular/core';
import {PartyEventProductResponse} from '@portal/api/responses/PartyEventProduct/party-event-product-response';
import {PartyEventProductsService} from '@portal/api/party-event-products.service';
import {SearchRequest} from '@portal/api/requests/search-request';
import {Guid} from 'guid-typescript';
import {ActivityBarService} from "./activity-bar.service";
import {AddActivityBarTransaction} from "@portal/api/contracts/activity-bar/add-activity-bar-transaction";
import {Success} from "@portal/api/responses/api-response";
import {ActivityBarTransaction} from "@portal/api/contracts/activity-bar/activity-bar-transaction";

@Component({
    selector: 'app-event-products-calculator',
    templateUrl: './event-products-calculator.component.html',
    styleUrls: ['./event-products-calculator.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class EventProductsCalculatorComponent {
    eventId: Guid;
    isCalculatorVisible: boolean;
    products: PartyEventProductResponse[];
    price: number;
    sortDirection = 1;
    isPartialDeletingCalculator: boolean;
    isChangePanelVisible: boolean;
    amountReceived: number | null = null;
    change: number = 0;
    showSuccessMessage = false;
    showErrorMessage = false;
    isLoading = false;
    isPanelLoading = false;
    errorMessage: string = '';
    totalRegistered: number = 0;


    constructor(
        private eventProductsService: PartyEventProductsService,
        private eventProductsCalculatorService: ActivityBarService,
    ) {
    }

    ngOnInit(): void {
        this.isPanelLoading = true;
        this.eventProductsCalculatorService.connect().subscribe(res => {
            this.isCalculatorVisible = res.open;
            if (this.isCalculatorVisible) {
                this.hideLayout();

                this.eventId = res.eventId;
                if (this.eventId) {
                    this.loadTotalRegistered();
                    this.eventProductsService.listByEvent(
                        {page: 0, pageSize: 600, sortDirection: this.sortDirection} as SearchRequest,
                        this.eventId).subscribe(resp => {
                        this.products = resp?.data?.items;
                        this.resetCalculatingScore();
                        this.isPanelLoading = false;
                    });
                }
            } else {
                this.resetLayout();
                this.products = [];
            }
        })

    }

    showChangePanel() {
        this.isCalculatorVisible = false;
        this.isChangePanelVisible = true;
    }

    onAmountReceivedChanged(value: number | null) {
        if (value != null) {
            this.amountReceived = value;
            this.calculateChange();
        } else {
            this.amountReceived = null;
            this.change = 0;
        }
    }

    calculateChange() {
        if (this.amountReceived != null) {
            this.change = this.amountReceived - this.price;
        } else {
            this.change = 0;
        }
    }

    goBack() {
        this.isChangePanelVisible = false;
        this.isCalculatorVisible = true;
        this.amountReceived = null;
    }

    registerTransactionByChange() {
        this.registerTransaction();
        this.goBack();
    }

    registerTransaction() {
        this.isLoading = true;
        this.showSuccessMessage = false;
        this.showErrorMessage = false;
        this.errorMessage = '';
        const selectedItems = this.products
            .filter(product => product.touchedTimes > 0)
            .map(product => ({
                id: product.id,
                count: product.touchedTimes,
                price: product.price
            }));

        const request = new AddActivityBarTransaction(this.eventId, selectedItems);

        this.eventProductsCalculatorService.addTransaction(request).subscribe(
            (resp: Success<any>) => {
                this.showSuccessMessage = true;
                setTimeout(() => {
                    this.showSuccessMessage = false;
                }, 3000);
                this.products.forEach(p => p.touchedTimes = 0);
                this.calculateScore();
                this.totalRegistered += resp.data.totalPrice;
                this.isLoading = false;
            },
            () => {
                this.isLoading = false;
                this.showErrorMessage = true;
                this.errorMessage = 'Ocorreu um erro ao registar a transação. Por favor, tente novamente.';

                setTimeout(() => {
                    this.showErrorMessage = false;
                }, 3000);
            }
        );

    }

    loadTotalRegistered(): void {
        this.eventProductsCalculatorService.getTotalRegistered(this.eventId).subscribe(
            (total: number) => {
                this.totalRegistered = total;
            },
            () => {
                this.totalRegistered = 0;
                console.error('Erro ao carregar o total acumulado.');
            }
        );
    }

    private hideLayout() {
        const elem = document.getElementById('layout-elem');
        if (elem) {
            elem.style.display = 'none';
        }
    }

    private resetLayout() {
        const elem = document.getElementById('layout-elem');
        if (elem) {
            elem.style.display = 'block';
        }
    }

    calculateScore() {
        this.price = this.products.reduce((accumulator, obj) => {
            return accumulator + (obj.touchedTimes * obj.price);
        }, 0);

    }

    priceClicked(id: Guid) {
        const pointClickedIndex = this.products.findIndex(x => x.id === id);
        if (!this.isPartialDeletingCalculator) {
            this.products[pointClickedIndex].touchedTimes += 1;
        } else {
            if (this.products[pointClickedIndex].touchedTimes > 0) {
                this.products[pointClickedIndex].touchedTimes -= 1;
            }
        }
        this.calculateScore();
    }

    resetCalculatingScore() {
        this.products.map(x => x.touchedTimes = 0);
        this.price = 0;
    }

    close() {
        this.eventProductsCalculatorService.modalSet({open: false})
    }

    partialDeleteCalculator() {
        this.isPartialDeletingCalculator = !this.isPartialDeletingCalculator;
    }
}

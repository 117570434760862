import {BehaviorSubject, Observable} from 'rxjs';
import {Injectable} from "@angular/core";
import {Guid} from "guid-typescript";
import {Success, SuccessList} from "@portal/api/responses/api-response";
import {ApiBaseService} from "@portal/api/api-base-service";
import {SearchRequest} from "@portal/api/requests/search-request";
import {parseObjectToQueryString} from "@portal/shared/utils";
import {ListService} from "@portal/api/base/list-service";
import {AddActivityBarTransaction} from "@portal/api/contracts/activity-bar/add-activity-bar-transaction";
import {ActivityBarTransaction} from "@portal/api/contracts/activity-bar/activity-bar-transaction";
import {SearchRequestFilter} from "@portal/api/requests/search-request-filter";

interface ModalCalculator {
    open: boolean;
    eventId: Guid;
}

@Injectable({
    providedIn: 'root'
})
export class ActivityBarService extends ApiBaseService implements ListService {
    controller = 'activity-bar';

    private modal = new BehaviorSubject<Partial<ModalCalculator>>({open: false});

    connect(): Observable<Partial<ModalCalculator>> {
        return this.modal.asObservable();
    }

    modalSet(config: Partial<ModalCalculator>) {
        this.modal.next({...config});
    }

    addTransaction(obj: AddActivityBarTransaction): Observable<Success<ActivityBarTransaction>> {
        return this.post<Success<ActivityBarTransaction>, AddActivityBarTransaction>(obj, this.controller);
    }

    list<ActivityBarTransactionList>(obj: SearchRequest): Observable<SuccessList<ActivityBarTransactionList>> {
        return this.get<SuccessList<ActivityBarTransactionList>>(this.controller + `?${parseObjectToQueryString(obj)}`);
    }

    getAll<ActivityBarTransactionList>(activityId: Guid): Observable<SuccessList<ActivityBarTransactionList>> {
        let request = {
            page: 0,
            pageSize: 1,
            sortDirection: 0,
            sortField: null,
            getAll: true
        } as SearchRequest;
        return this.get<SuccessList<ActivityBarTransactionList>>(this.controller + `?activityId=${activityId}&${parseObjectToQueryString(request)}`);
    }

    deleteTransaction(id: Guid): Observable<boolean> {
        return this.deleteBase<boolean>(`${this.controller}/${id}`);
    }

    getTotalRegistered(activityId: Guid): Observable<number> {
        return this.get<number>(`${this.controller}/total-registered/${activityId}`);
    }
}

import {Guid} from "guid-typescript";

export class AddActivityBarTransaction {
    activityId: Guid;
    products: {
        id: Guid;
        count: number;
        price: number;
    }[];

    constructor(eventId: Guid, items: { id: Guid; count: number, price: number }[]) {
        this.activityId = eventId;
        this.products = items;
    }
}

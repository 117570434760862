import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiBaseService } from './api-base-service';
import { SearchRequest } from './requests/search-request';
import { ApiResponse, ListResponse } from './responses/api-response';
import { PartyResponse } from './responses/Parties/party-response';
import { UserInfoResponse } from '@portal/api/responses/User/user-info-response';
import { Guid } from 'guid-typescript';
import { PartyAddRequest } from '@portal/api/requests/parties/party-add-request';
import { PartyNoteUpdateRequest } from '@portal/api/requests/party-notes/party-note-update-request';
import { PartyUpdateRequest } from '@portal/api/requests/parties/party-update-request';
import {PartySettings} from "@portal/api/requests/parties/party-settings-update-request";

@Injectable({
    providedIn: 'root'
})
export class PartyService extends ApiBaseService {
    controller = 'parties';

    constructor(public http: HttpClient) {
        super(http);
    }

    add(request: PartyAddRequest) {
        return this.post<ApiResponse<{id: Guid, orgId: Guid}>, PartyAddRequest>(request, this.controller + `/Add`);
    }

    update(request: PartyUpdateRequest): Observable<ApiResponse<Guid>> {
        return this.put<ApiResponse<Guid>, PartyUpdateRequest>(request, this.controller + `/update`);
    }

    updatePoster(id:string, file: File): Observable<ApiResponse<string>> {
        const formData = new FormData();
        formData.append("file", file);
        return this.putMultiPart<ApiResponse<string>, FormData>(formData, `${this.controller}/${id}/poster`);
    }

    getUserParties(obj: SearchRequest): Observable<ApiResponse<PartyResponse[]>> {
        return this.post<ApiResponse<PartyResponse[]>, SearchRequest>(obj, this.controller + `/ListActiveUserParties`);
    }

    getPartyUsers(obj: SearchRequest, partyId: Guid): Observable<ApiResponse<ListResponse<UserInfoResponse[]>>> {
        return this.post<ApiResponse<ListResponse<UserInfoResponse[]>>, SearchRequest>(obj, `${this.controller}/${partyId}/ListUsers`);
    }

    updateSettings(obj: PartySettings): Observable<ApiResponse<boolean>> {
        return this.post<ApiResponse<boolean>, PartySettings>(obj, `${this.controller}/UpdateSettings`);
    }

    getUpdateSettings(): Observable<ApiResponse<PartySettings>> {
        return this.get<ApiResponse<PartySettings>>(this.controller + `/GetSettings`);
    }

    deleteUser(userId: Guid, partyId: Guid): Observable<ApiResponse<boolean>> {
        return this.deleteBase<ApiResponse<boolean>>(`${this.controller}/deleteUser?userId=${userId}&partyId=${partyId}`);
    }

    getDetails(partyId:Guid): Observable<ApiResponse<PartyResponse>> {
        return this.get<ApiResponse<PartyResponse>>(this.controller + `/Details/${partyId}`);
    }

    getPublicDetails(partyId:Guid): Observable<ApiResponse<PartyResponse>> {
        return this.get<ApiResponse<PartyResponse>>(this.controller + `/public/${partyId}`);
    }
}

import {TimeSpan} from "../modules/admin/shared/helpers/timespan";

export function parseObjectToQueryString(obj) {
    const str = [];

    const addParam = (key, value) => {
        str.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    };

    for (const p in obj) {
        if (obj.hasOwnProperty(p)) {
            if (Array.isArray(obj[p])) {
                // Special handling for filters
                if (p === 'filters') {
                    obj[p].forEach((filter, index) => {
                        addParam(`filters[${index}].code`, filter.code);
                        filter.values.forEach((value, vIndex) => {
                            addParam(`filters[${index}].values[${vIndex}]`, value);
                        });
                    });
                } else {
                    obj[p].forEach((value, index) => {
                        addParam(`${p}[${index}]`, value);
                    });
                }
            } else if (typeof obj[p] === 'object') {
                // Handle nested objects if needed
                for (const subProp in obj[p]) {
                    if (obj[p].hasOwnProperty(subProp)) {
                        addParam(`${p}.${subProp}`, obj[p][subProp]);
                    }
                }
            } else {
                addParam(p, obj[p]);
            }
        }
    }

    return str.join("&");
}

export function calculateSubmitTime(timeValue: string, currentTimespan: TimeSpan | undefined): { updatedTimeSpan: TimeSpan, submitTime: number } {
    let updatedTimeSpan: TimeSpan;

    if (currentTimespan?.ticks) {
        updatedTimeSpan = currentTimespan;
    } else if (timeValue) {
        const [hours, minutes] = timeValue.split(':').map(Number);
        updatedTimeSpan = TimeSpan.fromHours(hours).add(TimeSpan.fromMinutes(minutes));
    } else {
        throw new Error(`Time value is missing or invalid`);
    }

    return { updatedTimeSpan, submitTime: updatedTimeSpan.ticks };
}

export function calculateSubmitTimeOnly(timeValue: string): number {
    if (!timeValue) {
        throw new Error(`Time value is missing or invalid`);
    }
    const [hours, minutes] = timeValue.split(':').map(Number);
    const updatedTimeSpan = TimeSpan.fromHours(hours).add(TimeSpan.fromMinutes(minutes));
    return updatedTimeSpan.ticks;
}

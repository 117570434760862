import {Injectable} from '@angular/core';
import {PartyService} from "@portal/api/party-service";
import {PartySettings} from "@portal/api/requests/parties/party-settings-update-request";
import {AuthService} from "../../core/auth/auth.service";
import {
    BehaviorSubject,
    debounceTime,
    filter,
    merge,
    shareReplay,
    startWith,
    tap
} from "rxjs";
import {StorageService} from "@portal/shared/services/storage.service";
import {OrganizationSelectorService} from "@portal/shared/services/organization-selector.service";
import {PartySelectedService} from "@portal/shared/services/party-selected.service";

@Injectable({
    providedIn: 'root',
})
export class PartySettingsService {
    private readonly currentPartySettingsKey = 'partySettings';

    private currentPartySettings = new BehaviorSubject<PartySettings>(null);
    private currentPartyEditable = new BehaviorSubject<boolean>(null);

    get isPartyEditable() {
        return this.currentPartySettings.getValue()?.isEditable;
    }

    get isTrial() {
        return this.currentPartySettings.getValue()?.isTrial;
    }

    get daysToExpire() {
        return this.currentPartySettings.getValue()?.daysToExpire;
    }

    get currentPartyEditable$() {
        return this.currentPartyEditable.asObservable().pipe(filter(item => item != null));
    }

    get currentPartySettings$() {
        return this.currentPartySettings.asObservable().pipe(
            // forces a start on null so it can ignore equal values from the storage
            // and never repeat emissions with same value
            startWith(null),
            shareReplay(),
            tap(value => {
                this.currentPartyEditable.next(value?.isEditable);
            }),
            filter(item => {
                if (item === null && item !== this.currentPartySettings.getValue()) {
                    return true;
                }
                return !(this.currentPartySettings.getValue()?.isEditable === item?.isEditable &&
                    this.currentPartySettings.getValue()?.hasDonationsValueVisible === item?.hasDonationsValueVisible &&
                    this.currentPartySettings.getValue()?.hasSponsorsValueVisible === item?.hasSponsorsValueVisible);
            }));
    }

    constructor(private _partyService: PartyService,
                private _storageService: StorageService,
                private _organizationSelectorService: OrganizationSelectorService,
                private _partySelectedService: PartySelectedService,
                private _authService: AuthService) {
        this.loadFromStorage();

        merge(
            this._partySelectedService.currentParty,
            this._authService.loginStatus.pipe(filter(isLoggedIn => isLoggedIn))
        ).pipe(debounceTime(100)).subscribe(() => {
            this.loadCurrentPartySettings();
        });
    }

    private loadFromStorage() {
        const settings = this._storageService.getObject(this.currentPartySettingsKey);
        if (settings) {
            this.currentPartyEditable.next(settings.isEditable);
            this.currentPartySettings.next(settings);
        }
    }

    loadCurrentPartySettings() {
        if (this._authService.isAuthenticated && this._partySelectedService.getPartyId()) {
            this._partyService.getUpdateSettings().subscribe(({data: {hasDonationsValueVisible, isEditable, hasSponsorsValueVisible,daysToExpire,isTrial}}) => {
                this.setSettings(hasDonationsValueVisible, isEditable,hasSponsorsValueVisible ,daysToExpire, isTrial);
            });
        }
    }

    private setSettings(hasDonationsValueVisible, isEditable, hasSponsorsValueVisible,daysToExpire,isTrial) {
        const currentSettings = {
            hasDonationsValueVisible,
            isEditable,
            hasSponsorsValueVisible,
            daysToExpire,
            isTrial
        };

        this.currentPartySettings.next(currentSettings);
        this.currentPartyEditable.next(currentSettings.isEditable);
        this._storageService.setObject(this.currentPartySettingsKey, currentSettings);
    }
}

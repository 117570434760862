export class TimeSpan {
    private _milliseconds: number;

    constructor(milliseconds: number) {
        this._milliseconds = milliseconds;
    }

    static fromHours(hours: number): TimeSpan {
        return new TimeSpan(hours * 60 * 60 * 1000);
    }

    static fromMinutes(minutes: number): TimeSpan {
        return new TimeSpan(minutes * 60 * 1000);
    }

    static ticksToTimeString(ticks: number): string {
        const ticksPerMillisecond = 10000; // Um tick tem 100 nanossegundos, então 10000 ticks são iguais a 1 milissegundo
        const epochTicks = 621355968000000000; // Ticks no epoch (1 de janeiro de 1970)

        const ticksSinceEpoch = ticks - epochTicks;
        const millisecondsSinceEpoch = ticksSinceEpoch / ticksPerMillisecond;

        const date = new Date(millisecondsSinceEpoch);
        const hours = date.getUTCHours().toString().padStart(2, '0');
        const minutes = date.getUTCMinutes().toString().padStart(2, '0');

        return `${hours}:${minutes}`;
    }



    add(other: TimeSpan): TimeSpan {
        return new TimeSpan(this._milliseconds + other._milliseconds);
    }

    get totalMilliseconds(): number {
        return this._milliseconds;
    }

    get ticks(): number {
        return this._milliseconds * 10000;
    }

    get totalSeconds(): number {
        return this._milliseconds / 1000;
    }

    get totalMinutes(): number {
        return this.totalSeconds / 60;
    }

    get totalHours(): number {
        return this.totalMinutes / 60;
    }
}

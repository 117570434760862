import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode} from '@angular/common/http';
import {BehaviorSubject, catchError, filter, finalize, Observable, of, switchMap, take, throwError} from 'rxjs';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthToken} from '../../api/responses/Auth/auth-token';
import {AccountService} from "@portal/api/account.service";
import {ApiResponse} from "@portal/api/responses/api-response";
import {StorageService} from "@portal/shared/services/storage.service";
import {PartySelectedService} from "@portal/shared/services/party-selected.service";
import {ActivatedRoute, Router, RouterStateSnapshot} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    refreshTokenInProgress = false;
    refreshTokenSubject = new BehaviorSubject<AuthToken>(null);

    constructor(private authService: AuthService,
                private router: Router,
                private route: ActivatedRoute,
                // private  state: RouterStateSnapshot,
                private _accountService: AccountService,
                private _partySelectedService: PartySelectedService,
                private _storageService: StorageService) {

    }

    // https://itnext.io/angular-tutorial-implement-refresh-token-with-httpinterceptor-bfa27b966f57
    // https://github.com/melcor76/interceptors/blob/master/src/app/interceptors/auth.interceptor.ts
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        request = this.addAuthenticationToken(request);
        const requestToIgnore = request.url.includes('RefreshAccessToken') || request.url.includes('Login');

        if (this.authService.isAuthenticated && !requestToIgnore && this.authService.isTokenExpired()) {
            if (!this.refreshTokenInProgress) {
                return this.doRefreshToken(request, next);
            } else {
                return this.setRequestsOnHold(request, next);
            }
        } else {
            return next.handle(request).pipe(
                catchError(error => {
                    if (requestToIgnore) {
                        // @ts-ignore
                        if (request.url.includes('RefreshAccessToken') && !error.error.error.code === 8) {
                            // TODO your login  session has expired please login again
                            this.authService.signOut();
                        } else if (error.error.error.code === 8) {
                            return this.doRefreshToken(request, next);
                        }
                        return throwError(error);
                    }
                    if (error.status !== HttpStatusCode.Unauthorized) {
                        /*  if (error.status === HttpStatusCode.Forbidden) {
                              // this.authService.signOut();
                              this._router.navigate(['/']);
                          }*/
                    } else {

                        if (this.refreshTokenInProgress) {
                            return this.setRequestsOnHold(request, next);
                        } else {
                            return this.doRefreshToken(request, next);
                        }
                    }
                    return throwError(error);
                }));
        }
    }

    private doRefreshToken(request, next): Observable<any> {
        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieve
        this.refreshTokenInProgress = true;

        // setting to nul makes that all the subsequents API calls wait until token has ben refreshed
        this.refreshTokenSubject.next(null);

        return this._accountService.getNewAccessToken(this.authService.refreshToken).pipe(
            switchMap((response: ApiResponse<AuthToken>) => {

                // Replace the access token with the new one if it's available on
                // the response object.
                //
                // This is an added optional step for better security. Once you sign
                // in using the token, you should generate a new one on the server
                // side and attach it to the response object. Then the following
                // piece of code can replace the token with the refreshed one.
                if (response.data) {
                    this.authService.authToken = response.data;
                    this.authService.setIsLoggedIn(true);
                }
                return of(true);
            }),

            switchMap(token => {
                this.refreshTokenSubject.next(this.authService.getAuth());
                return next.handle(this.addAuthenticationToken(request));
            }),
            catchError((err) => {
                if (err.status === HttpStatusCode.BadRequest || err.status === HttpStatusCode.Unauthorized || err.error.code === 7) {
                    this.authService.signOut();
                    // Set router snapshot to redirect to the current URL
                    // if the user is not authenticated
                    const redirectURL = this.route.snapshot['_routerState'].url;
                    this.router.navigate(['sign-in'], {queryParams: {redirectURL}});
                    //this.router.navigate(['/sign-in']);
                }

                return of(false);
            }),
            finalize(() => this.refreshTokenInProgress = false)
        );
    }

    private setRequestsOnHold(request, next): Observable<any> {
        // Once refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
        // which means the new token is ready and we can retry the request again
        return this.refreshTokenSubject
            .pipe(
                filter(result => result !== null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(request))));
    }

    private addAuthenticationToken(request): HttpRequest<any> {
        const token = this.authService.accessToken;

        // If access token is null this means that user is not logged in
        if (!token) {
            return request;
        }

        let contentType = null;
        if (request.headers.has('Content-Type')) {
            contentType = request.headers.get('Content-Type');
        }
        const authHeader = this.getHeaders(contentType);
        if (authHeader) {
            return request.clone({
                setHeaders: authHeader
            });
        }
    }

    getHeaders(contentType = 'application/json'): any {
        const headers: any = contentType ? {'Content-Type': contentType} : {};
        const accessToken = this.authService.accessToken;
        if (accessToken) {
            headers.Authorization = 'Bearer ' + accessToken;

            const partyId = this._partySelectedService.getPartyId();
            if (partyId) {
                headers.PartyId = partyId.toString();
            }

            // storageService is required here becouse
            // organizationsSelectorService will throw cyclic
            // injection in some cenarios
            if (this._storageService.getOrg()?.id) {
                headers.orgId = this._storageService.getOrg().id;
            }
        }
        return headers;
    }

}
